import { render, staticRenderFns } from "./interval-dropdown.vue?vue&type=template&id=a2478492&scoped=true&"
import script from "./interval-dropdown.vue?vue&type=script&lang=js&"
export * from "./interval-dropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2478492",
  null
  
)

export default component.exports