<script>
import DashboardService from "@/services/dashboard";
import { DataListBasedPanels } from "@/services/dashboard";
import { isUUID } from "@/utils";
import isEqual from "lodash/isEqual";

export default {
  name: "DashboardEquipmentBase",
  props: {
    equipmentId: {
      type: [Number, String], // TODO by using strings we will allow more than one equipment in near future (1,2,...):
      required: false,
      default: () => 0
    },
    mode: {
      type: String,
      required: false,
      default: () => ""
    },
    panelName: {
      type: String,
      required: false,
      default: () => ""
    },
    screenId: {
      type: [Number, String],
      required: false,
      default: () => ""
    }
  },
  data() {
    return {
      dashboardError: "",
      // screenState: "idle",
      equipmentState: "idle",
      source: null,
      mDisplay: null,
      mEquipment: null,
      resourcesLoading: false,
      tmpScreenId: null,
      forceUpdate: false
    };
  },
  computed: {
    display() {
      // return this.dashboardMode == "editor"
      //   ? this.$store.getters["dashboard/draft"]?.template || null
      //   : this.mDisplay;
      return this.mDisplay;
    },
    equipment: {
      set(equipment) {
        if (this.equipmentId) {
          if (equipment && equipment.id == this.equipmentId) {
            this.$set(this, "mEquipment", equipment);
          }
        } else {
          this.$set(this, "mEquipment", null);
        }
      },
      get() {
        return this.mEquipment;
      }
    },
    templateReady() {
      return this.$store.getters["dashboard/isReady"];
    },
    isReady() {
      return this.display &&
        this.$store.getters["user/isSettingsLoaded"] &&
        (this.equipmentState == "idle" || this.equipmentState == "ready")
        ? true
        : false;
    },
    selectedScreenId() {
      let screenId = (
        this.tmpScreenId ||
        (this.screenId ? parseInt(this.screenId) : this.equipment?.screen_id)
      );
      if (screenId && (screenId < 0 || screenId && (this.screenList || []).some(({ id }) => parseInt(id) == parseInt(screenId)))) {
        return screenId;
      }
      else {
        return (this.screenList || []).find((i) => "default" in i && i.default).id;
      }
    },
    dashboardMode() {
      return this.$store.getters["dashboard/mode"];
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"];
    },
    media() {
      if (this.$store.getters["dashboard/mode"] == "editor") return "screen";
      return (
        this?.$route?.query?.media ||
        (this.equipmentTemplate() && this.equipmentTemplate()?.media) ||
        "screen"
      );
    },
    refreshInterval() {
      // !IMPORTANT NOTE!
      // The legacy value of this property was stored as 5m (300000), which
      // are now a too high standard. Therefore, 5min will be set to 300001ms and legacy
      // value will be replaced by the default one (30s)
      const _default = this?.$root?.config?.pooling_interval?.value || 30000;
      if (!this.mDisplay) return _default;
      return this.mDisplay.refreshInterval == 300000
        ? _default
        : this.mDisplay.refreshInterval;
    },
    connectorList() {
      return this.$store.getters["dashboard/connectorList"];
    },
    qstr() {
      let result = { ...(this.$route.query || {}) };
      if ("_cdim" in this.$route.query) {
        result = {
          ...JSON.parse(localStorage.getItem("_cdim") || "{}"),
          ...result
        };
      }
      for (var p in result) {
        if (result[p] && /^\$\(.*$/.test(result[p])) {
          result[p] = this.$root.$formatter.format({ template: result[p] });
        }
      }
      return result;
    },
    routePath() {
      return this.$route.path;
    },
    deviceId() {
      return parseInt(this?.qstr?.device_id || this?.qstr?.deviceId || 0);
    },
    screenState() {
      return this.$store.getters["dashboard/hasPrivateScreensLoaded"] ? "ready" : "loading"
    },
    screenList() {
      const lst = (this.$store.getters["dashboard/screens"] || []);
      return (this.mode == "editor") ? lst : lst.filter(
        ({ id, deleted_at, portal_data }) => id > -1 && !deleted_at && (!portal_data || !portal_data.excluded_at)
      )
    }
  },
  watch: {
    equipmentId: {
      immediate: true,
      handler(n, o) {
        if (n) {
          // this.$nextTick(() => {
          this.setDashboardEquipment(n);
          if (this.equipment && this.equipment.id !== n) {
            if (this.getEquipment(n)) {
              this.equipmentState = "changing";
              this.$nextTick(() => {
                this.equipmentState = "ready";
              });
              return;
            }
          }
          if (o) {
            this.equipmentState = "changing";
            this.mDisplay = null;
            this.resourcesLoading = false;
          }
          if (this.getEquipment(n)) {
            this.equipmentState = "changing";
            this.$nextTick(() => {
              this.equipmentState = "ready";
            });
            return;
          } else {
            this.fetchEquipment();
          }
        }
        else if (o) {
          this.equipment = null;
          this.equipmentState = "idle";
          this.setDashboardEquipment(0);
        }
      }
    },
    templateReady(ready) {
      if (ready) {
        if (this.equipmentTemplate()) {
          if (!this.isLatestTemplate()) {
            this.fetchDashboard();
            return;
          }
          this.setupDataList();
        } else {
          this.validateTemplate();
        }
      } else {
        this.mDisplay = null;
      }
    },
    async screenState(state) {
      if (state == "ready") {
        if (this.screen()) {
          if (this.mode == "editor") {
            await this.initDraft(this.selectedScreenId);
            if (this.equipmentTemplate()) this.setupDataList();
            else this.validateTemplate();
          } else {
            if (this.equipmentTemplate()) {
              this.setupDataList();
            } else {
              this.fetchDashboard().then((template) => {
                if (template && !this.source) {
                  this.setupDataList();
                }
              });
            }
          }
        } else if ((this.equipmentState == "ready")) {
          this.validateTemplate();
        } else if ((this.equipmentState == "idle") && this.$route?.query?.tpl == "draft") {
          // this.fetchDashboard().then((template) => {
          //       if (template && !this.source) {
          //         this.setupDataList();
          //       }
          //     });
        }

      }
    },
    async selectedScreenId(id) {
      if (id) {
        let prv = this.$store.getters["dashboard/dashboardScreenId"] || "";
        if (prv !== "" && prv != id && this.display) {
          if (!this.deviceId && (this.$store.getters["deviceId"] || 0)) {
            this.$store.commit("SET_DEVICE_ID", "");
            this.forceUpdate = true;
          }
          this.mDisplay = null;
        }
        this.setDashboardScreen(id);
        if (!this.screen()) {
          return;
        } else {
          if (this.mode == "editor") {
            await this.initDraft(this.selectedScreenId);
            if (this.equipmentTemplate()) this.setupDataList();
            else this.validateTemplate();
          } else {
            if (this.equipmentTemplate()) {
              if (!this.display && !this.resourcesLoading) {
                if (!this.isLatestTemplate()) {
                  this.fetchDashboard();
                  return;
                }
                this.setupDataList();
              }
            } else {
              this.fetchDashboard();
            }
          }
        }
      }
    },
    equipment(n, o) {
      if ((n && !o) || (n && o && !isEqual(n.id, o.id))) {
        this.setup();
        this.onEquipmentUpdated();
      }
    },
    equipmentState(state) {
      if (
        state == "ready" &&
        this.equipmentTemplate() &&
        !this.isLatestTemplate()
      ) {
        this.fetchDashboard();
        return;
      }
      this.$nextTick(() => {
        if (state == "ready") {
          this.equipment = this.getEquipment(this.equipmentId);
          if (this.equipment) {
            if (this.equipmentTemplate()) {
              if (!this.isLatestTemplate()) {
                this.fetchDashboard();
                return;
              }
              if (!this.display && !this.resourcesLoading) {
                this.setupDataList();
              }
            }
          } else if (!this.selectedScreenId) {
            this.validateTemplate();
          }
        }
      });
    },
    isReady(n, o) {
      if (!o && n && !this.dashboardError) {

        if (!this.hasPermission) {
          this.dashboardError =
            "you_do_not_have_access_to_the_requested_resource";
          return;
        }
      }
    },
    $route(n, o) {
      if (this.deviceId) {
        if ((this.$store.getters["deviceId"] || 0) != this.deviceId) {
          this.$store.commit("SET_DEVICE_ID", this.deviceId);
        }
      } else {
        this.$store.commit("SET_DEVICE_ID", "");
      }
      if (n && (!o || (n.path == o.path && n.fullPath != o.fullPath))) {
        // only query parameters were changed - just update the current template
        this.setupDataList();
      }
    },

  },
  methods: {
    async initDraft(screenId) {
      this.mDisplay = null;
      return this.$store.dispatch("dashboard/initDraft", screenId);
    },
    async createNewSession() {
      return this.$store.dispatch("dashboard/createNewSession");
    },
    async resetSession() {
      return this.$store.dispatch("dashboard/resetSession");
    },
    async refresh() {
      return this.$store.dispatch("dashboard/refresh");
    },
    onEquipmentUpdated() {
      // Might be reimplemented 
    },
    getEquipment(equipmentId) {
      return this.connectorList?.find?.(({ id }) => id == equipmentId) || null;
    },
    equipmentTemplate() {
      return this.mode == "editor"
        ? this.$store.getters["dashboard/draft"]?.template
        : this.$store.getters["dashboard/template"](this.selectedScreenId);
    },
    screen() {
      return this.$store.getters["dashboard/screen"](this.selectedScreenId);
    },
    setupDraft() {
      return new Promise((resolve) => {
        if (!this.screenId) {
          resolve(null);
          return;
        }
        let draft = this.$store.getters["dashboard/draft"] || null;
        if (draft && draft.screenId == this.screenId && draft.template) {
          this.$store.commit("dashboard/SET_TEMPLATE", {
            id: this.screenId,
            data: draft.template
          });
          resolve(draft.template);
        } else {
          this.$store
            .dispatch("dashboard/initDraft", this.screenId)
            .then(() => {
              draft = this.$store.getters["dashboard/draft"] || null;
              if (draft && draft.screenId == this.screenId && draft.template) {
                this.$store.commit("dashboard/SET_TEMPLATE", {
                  id: this.screenId,
                  data: draft.template
                });
                resolve(draft.template);
              } else {
                resolve(null);
              }
            });
        }
      });
    },
    fetchDashboard() {
      if (
        this.mode != "editor" &&
        this.screenId &&
        this.$route?.query?.tpl == "draft"
      ) {
        return this.setupDraft();
      } else {
        return this.$store.dispatch(
          "dashboard/fetchTemplate",
          this.selectedScreenId
        );
      }
    },
    fetchEquipment() {
      return this.$store
        .dispatch("dashboard/fetchResources", {
          list: [this.equipmentId],
          resource: "connector",
          forceUpdate: false
        })
        .then(() => (this.equipmentState = "ready"));
    },
    requireDataListInitialization() {
      if (this.resourcesLoading) return false;
      const resourceIds = this?.source?.resourceIds || [];
      const referenceIds = this?.source?.referenceIds || [];
      const cId = parseInt(this.equipmentId || 0);
      const dId = parseInt(this.deviceId || 0);
      const nResourceIds = resourceIds.length
        ? resourceIds.filter((i) => {
          if (i.resource == "data" && i.id) {
            return (this.$store.getters["dashboard/dataList"] || []).some(
              ({ id }) => parseInt(id) == parseInt(i.id)
            );
          }
          return false;
        }).length
        : -1;
      const nReferenceIds = referenceIds.length
        ? referenceIds.filter((i) => {
          if (i.resource == "data" && i.id) {
            return (this.$store.getters["dashboard/dataList"] || []).some(
              (data) => {
                if (i.id.split("/").slice(-1)[0] !== data.reference_id)
                  return false;
                if (cId && parseInt(data.clp_id) !== cId) return false;
                if (dId && parseInt(data?.device?.id) !== dId) return false;
                return true;
              }
            );
          }
          return false;
        }).length
        : -1;
      return !(
        (nResourceIds == -1 || nResourceIds == resourceIds.length) &&
        (nReferenceIds == -1 || nReferenceIds == referenceIds.length)
      );
    },
    fetchResourceList() {
      if (
        (!this?.source?.resourceIds.length &&
          !this?.source?.referenceIds?.length) ||
        this?.qstr.device_id ||
        this?.qstr.deviceId
      ) {
        return this.fetchConnectorDataList(this.forceUpdate);
      }
      this.resourcesLoading = true;
      return this.$store
        .dispatch("dashboard/fetchResourceList", {
          source: {
            resourceIds: this.source.resourceIds,
            referenceIds: this.$utils.distinct(
              (this.source.referenceIds || []).map((i) => ({
                ...i,
                id: (i?.id || "").split("/").slice(-1)[0] // only the data part
              }))
            ),
            connectorId: this.source.names.length ? this.equipmentId : null
          },
          forceUpdate: this.forceUpdate,
          once: true
        })
        .then((r) => {
          this.forceUpdate = false;
          this.resourcesLoading = false;
          return r;
        });
    },
    fetchConnectorDataList() {
      if (this.resourcesLoading) {
        return new Promise((resolve) => {
          resolve();
        });
      }
      this.resourcesLoading = true;
      let query = {
        resource: "data",
        connectorId: this.equipmentId || null,
        forceUpdate: true,
        once: true
      };
      let deviceId = this?.qstr.device_id || this?.qstr.deviceId || "";
      if (deviceId) {
        query.device_id = deviceId;
      }
      return this.$store
        .dispatch("dashboard/fetchResourcesFrom", query)
        .then((r) => {
          this.resourcesLoading = false;
          return r;
        });
    },
    setDisplay() {
      if (!this.equipmentTemplate()) {
        console.log("setDisplay - no template");
        return;
      }
      let deviceId = parseInt(
        this.qstr.device_id ||
        this.qstr.deviceId ||
        this.$store.getters["deviceId"] ||
        0
      );
      let data = {
        data_id: {
          ...this.source.referenceIds.reduce((obj, { id, connectorId }) => {
            obj[id] =
              this.dataList.find((data) => {
                if (deviceId) {
                  return (
                    parseInt(data.device.id) == parseInt(deviceId) &&
                    data.reference_id == id.split("/").slice(-1)[0]
                  );
                } else {
                  return (
                    parseInt(data.device.connector.id) ==
                    parseInt(connectorId) &&
                    ((id || "").indexOf("/") > 0
                      ? `${data.device.reference_id}/${data.reference_id}`
                      : `${data.reference_id}`) == id
                  );
                }
              })?.id ||
              id ||
              ""; // default id avoids the murdering of the data_id with an undefined value
            return obj;
          }, {}),
          ...this.source.names.reduce((obj, name) => {
            let data = this.dataList.find(
              (data) =>
                (data.name == name ||
                  `${data.device.name}.${data.name}` == name) &&
                data.device.connector.id == this.equipmentId
            );
            if (data) obj[name] = data.id;
            return obj;
          }, {})
        }
      };
      let display = new DashboardService().renderDashboardConfiguration(
        this.equipmentTemplate(),
        data
      );
      if (this.dashboardMode == "editor") {
        this.$store
          .dispatch("dashboard/initEditor", {
            screenId: this.screenId,
            template: display,
            panelName: this.panelName || ""
          })
          .then(() => {
            this.$set(
              this,
              "mDisplay",
              this.$store.getters["dashboard/draft"].template
            );
          });
      } else {
        if (
          deviceId &&
          parseInt(this.$store.getters["deviceId"]) != parseInt(deviceId)
        ) {
          this.$store.commit("SET_DEVICE_ID", deviceId);
        }
        this.$set(this, "mDisplay", display);
        this.$store.dispatch("dashboard/setMode", this.mode);
        this.$store.dispatch("dashboard/expand", null);
      }
      this.$root.$emit("dashboardReady");
    },
    setupDataList() {
      let template = this.equipmentTemplate();
      if (!template) return;
      if (this.resourcesLoading) return;
      if ("_cdim" in this.$route.query) {
        this.parseActionParams(template, this.qstr);
      }
      this.source = this.getDataIds(template);
      if (this.requireDataListInitialization()) {
        this.fetchResourceList().then(() => {
          this.setDisplay();
        });
      } else {
        this.$nextTick(() => {
          this.setDisplay();
        });
      }
    },
    parseActionParams(template, params) {
      (template?.panels || []).forEach((panel) => {
        if (panel.template in DataListBasedPanels) {
          DataListBasedPanels[panel.template].parser(
            panel,
            params,
            this.dataList
          );
        }
      });
    },
    getDataIds(template, recursive = false) {
      let resourceIds = {},
        referenceIds = {},
        names = {};
      for (let prop in template) {
        if (typeof template[prop] == "object") {
          let {
            resourceIds: reId,
            referenceIds: refId,
            names: n
          } = this.getDataIds(template[prop], true);
          resourceIds = { ...resourceIds, ...reId };
          referenceIds = { ...referenceIds, ...refId };
          names = { ...names, ...n };
        } else if (prop == "data_id" || isUUID(template[prop])) {
          if (isUUID(`${template[prop]}`.split("/")[0])) {
            referenceIds[template[prop]] =
              typeof template.connector_id == "number" &&
                this.dashboardMode == "editor"
                ? { connId: template.connector_id, resource: "data" }
                : { connId: true, resource: "data" };
          } else if (typeof template[prop] == "number") {
            resourceIds[template[prop]] = "data";
          } else if (
            typeof template[prop] == "string" &&
            template[prop].match(/connector_(\d+)_.+/)
          ) {
            resourceIds[template[prop].match(/connector_(\d+)_.+/)[1]] =
              "connector";
          } else if (
            typeof template[prop] == "string" &&
            template[prop].match(/device_(\w+)_.+/)
          ) {
            let id = template[prop].match(/device_(\w+)_.+/)[1];
            isUUID(id)
              ? (referenceIds[id] = {
                connId: true,
                resource: "device"
              })
              : !isNaN(parseInt(id))
                ? (resourceIds[id] = "device")
                : null;
          } else if (
            typeof template[prop] == "string" &&
            !template[prop].includes("connector") &&
            !template[prop].includes("device") &&
            template[prop]
          ) {
            names[template[prop]] = true;
          }
        }
      }

      if (!recursive) {
        resourceIds = Object.keys(resourceIds).map((id) => ({
          id: parseInt(id),
          resource: resourceIds[id]
        }));
        names = Object.keys(names).map((name) => name);
        referenceIds = Object.keys(referenceIds).map((id) => ({
          id,
          connectorId:
            typeof referenceIds[id].connId == "number"
              ? referenceIds[id].connId
              : this.equipmentId,
          resource: referenceIds[id].resource
        }));
      }
      return { resourceIds, referenceIds, names };
    },
    async setup() {
      let template = this.equipmentTemplate();
      if (this.mode == "editor") {
        if (template) {
          this.setupDataList();
        } else if (!this.screen()) {
          return;
        } else {
          await this.initDraft(this.selectedScreenId);
          if (this.equipmentTemplate()) this.setupDataList();
          else this.validateTemplate();
        }
      } else {
        if (!this.screen()) {
          return;
        } else if (!template) {
          this.fetchDashboard();
        } else {
          if (this.isLatestTemplate()) {
            this.setupDataList();
          } else {
            // it requires update once linked screens were updated
            this.fetchDashboard();
          }
        }
      }
    },
    refreshDashboard(connId, skipConnectionStatus) {
      if (this.dashboardMode == "editor") {
        this.mDisplay = null;
        this.setup();
      }
      // // test only
      this.$store.dispatch("dashboard/fetchDataSamples").then(() => {
        //this.$root.$emit("refreshDone");
      });
      if (this.media == 'print') return;
      this.$store
        .dispatch("dashboard/fetchResourcesState", {
          connectorIdList: [connId ? connId : this.equipmentId],
          skipConnectionStatus: skipConnectionStatus ? true : false
        })
        .then(() => {
          this.$root.$emit("refreshDone");
        });
      // return;
      // this.refresh().then(() => this.$root.$emit("refreshDone"));
    },
    setDashboardEquipment(id) {
      this.$store.commit("dashboard/SET_DASHBOARD_EQUIPMENT_ID", id);
    },
    setDashboardScreen(id) {
      this.$store.commit("dashboard/SET_DASHBOARD_SCREEN_ID", id);
    },
    isLatestTemplate() {
      let template = this.equipmentTemplate();
      if (template) {
        if (Object?.keys(template?.linkedPanels || {}).length) {
          let screen = null;
          for (var k in template?.linkedPanels) {
            screen =
              (this.$store.getters["dashboard/screens"] || []).find(
                ({ id }) => template.linkedPanels[k].screenId == id
              ) || null;
            if (screen && screen.etag != template.linkedPanels[k].etag) {
              return false;
            }
          }
        }
        return true;
      }
      return false;
    },
    validateTemplate() {
      if (this._validateTemplateTimer) {
        clearTimeout(this._validateTemplateTimer);
        this._validateTemplateTimer = null;
      }
      this._validateTemplateTimer = setTimeout(() => {
        clearTimeout(this._validateTemplateTimer);
        this._validateTemplateTimer = null;
        if (this.isReady && this.equipmentState == "ready" && !this.equipmentTemplate()) {
          this.dashboardError = "configured_screen_file_not_found";
        }
      }, 1000, this);
    }
  },
  beforeCreate() {
    this.$store.commit("SET_DEVICE_ID", "");
    this.$store.commit("dashboard/CREATE_SESSION");
  },
  created() {
    if (this.selectedScreenId) {
      this.setDashboardScreen(this.selectedScreenId);
      if (!this.equipmentId) {
        this.setup();
      }
    }
    this.$emit("equipmentChanged", this.equipmentId);
    this.$root.$on("refreshPage", this.refreshDashboard);
  },
  beforeDestroy() {
    this.$store.commit("SET_DEVICE_ID", "");
    this.$root.$off("refreshPage", this.refreshDashboard);
    this.resetSession();
  }
};
</script>
