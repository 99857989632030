<template>
  <div class="me" v-if="!busy">
    <template v-if="uiConfig == 'both'">
      <div
        ref="JQuerySlot"
        class="JQuerySlot"
        :class="{ 'with-ranges': shortcuts, 'no-sidebar': !hasSidebar }"
      ></div>
      <div class="input-group daterange-container">
        <input
          ref="inp"
          type="text"
          class="form-control"
          name="datetimes"
          :style="{
            'font-size': fontSize ? fontSize : '10pt'
          }"
          :title="
            timezoneError
              ? $t('different_system_timezone_detected')
              : $t('titles.insert_date_interval')
          "
          :disabled="!canEdit || rebuilding"
          @click.stop
        />
        <!-- <i
          class="fa fa-refresh refresh"
          v-if="showRefreshIcon"
          :title="$t('hints.integrated_refresh')"
        ></i> -->
        <ToolTip
          v-if="showRefreshIcon"
          icon="fa fa-refresh refresh"
          :title="$t('hints.integrated_refresh')"
        />
        <div
          class="input-group-addon btn btn-sm"
          :title="
            timezoneError
              ? $t('different_system_timezone_detected')
              : $t('titles.insert_date_interval')
          "
          @click.stop.prevent="toggle"
          :disabled="!canEdit || rebuilding"
        >
          <i
            class="fa fa-calendar"
            :class="timezoneError ? 'text-danger' : ''"
          ></i>
        </div>
      </div>
    </template>
    <template v-if="uiConfig == 'button'">
      <div ref="JQuerySlot" class="JQuerySlot"></div>
      <div ref="inp" />
      <span class="btn btn-sm" @click="toggle"
        ><i class="fa fa-calendar"></i
      ></span>
    </template>
    <template v-if="uiConfig == 'input'">
      <div ref="JQuerySlot" class="JQuerySlot"></div>
      <div class="input-group daterange-container">
        <input
          ref="inp"
          type="text"
          class="form-control"
          name="datetimes"
          :style="{
            'font-size': fontSize ? fontSize : '10pt',
            'max-width': '150px'
          }"
          :title="
            timezoneError
              ? $t('different_system_timezone_detected')
              : $t('titles.insert_date_interval')
          "
          :disabled="!canEdit || rebuilding"
        />
        <!-- <i
          class="fa fa-refresh refresh"
          v-if="showRefreshIcon"
          :title="$t('hints.integrated_refresh')"
        ></i> -->
        <ToolTip
          v-if="showRefreshIcon"
          icon="fa fa-refresh refresh"
          :title="$t('hints.integrated_refresh')"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { isEqual } from "lodash";
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "DatetimeRangePicker",
  components: {
    ToolTip
  },
  props: {
    startRef: {
      type: Object,
      required: false,
      default: () => moment()
    },
    endRef: {
      type: Object,
      required: false,
      default: () => null
    },
    fontSize: {
      type: String,
      default: () => "",
      required: false
    },
    min: {
      type: Number,
      default: () => 720, // a month
      required: false
    },
    allowFutureDate: {
      type: Boolean,
      default: () => false,
      required: false
    },
    restricted: {
      type: Boolean,
      default: () => false,
      required: false
    },
    uiConfig: {
      type: String,
      default: () => "both",
      required: false
    },
    shortcuts: {
      type: Boolean,
      default: true,
      required: false
    },
    showRefreshIcon: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data: function() {
    return {
      startDate: null, // todo: move it to the selection component
      endDate: null,
      busy: false,
      timezoneError: false,
      rebuilding: false,
      hasSidebar: true
    };
  },
  computed: {
    canEdit() {
      let user = this.$store.getters["user/loggedUser"] || null;
      return user ? !user.is_public_access : false;
    },
    start() {
      return this.startDate || this.startRef || this.defaultInterval()[0];
    },
    end() {
      return this.endDate || this.endRef || this.defaultInterval()[1];
    },
    portalData() {
      let user = this.$store.getters["user/loggedUser"] || {};
      return user?.user_profile?.portal_data || null;
    }
  },
  watch: {
    portalData: {
      handler(n, o) {
        if (!this?.$refs?.inp || isEqual(n, o)) return;
        let $picker = $(this.$refs.inp).data("daterangepicker");
        if ($picker) {
          $picker.setStartDate(this.userTimezone(this.start._d));
          $picker.setEndDate(this.userTimezone(this.end._d));
          this.validateTimezone();
          this.$refs.inp.click();
        }
      },
      deep: true
    },
    shortcuts() {
      this.safeRebuild();
    },
    uiConfig() {
      this.safeRebuild();
    }
  },
  methods: {
    defaultInterval() {
      return [moment().subtract(24, "hours"), moment()];
    },
    calendarRanges() {
      if (!this.shortcuts) return null;
      let _parse = (di, de) => {
        return !this.min ||
          Math.abs(this.min) > moment.duration(de.diff(di)).asHours()
          ? [di, de]
          : null;
      };
      let ranges = {};
      let range;
      //
      (range = _parse(moment().startOf("day"), moment())) &&
        (ranges[this.$t("calendar.today")] = range);
      //
      (range = _parse(
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day")
      )) && (ranges[this.$t("calendar.yesterday")] = range);
      //
      ranges[this.$t("calendar.last_24_hours")] = this.defaultInterval();
      //
      (range = _parse(moment().subtract(6, "days").startOf("day"), moment())) &&
        (ranges[this.$t("calendar.last_7_days")] = range);
      //
      (range = _parse(
        moment().subtract(29, "days").startOf("day"),
        moment()
      )) && (ranges[this.$t("calendar.last_30_days")] = range);
      //
      (range = _parse(moment().startOf("month"), moment().endOf("month"))) &&
        (ranges[this.$t("calendar.this_month")] = range);

      //
      (range = _parse(
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month")
      )) && (ranges[this.$t("calendar.last_month")] = range);

      return ranges;
    },
    validateTimezone() {
      const sysTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const usrTimezone = this?.portalData?.timezone || sysTimezone;
      this.timezoneError = sysTimezone != usrTimezone;
    },
    toggle: function() {
      if (!this.canEdit) return;
      let $picker = this.picker();
      if ($picker) {
        $picker.toggle();
      }
    },
    refresh() {
      this.$emit("interval-changed", {
        startDate: this.startDate,
        endDate: this.endDate
      });
    },
    userTimezone(dt) {
      let timezone = this?.portalData?.timezone || "";
      let value = timezone ? moment(dt).tz(timezone) : moment(dt);
      if (!this.restricted) {
        // value.minute(Math.round(value.minute() / 30) * 30);
      }
      value.second(0);
      return value;
    },
    onIntervalChange(startDate, endDate) {
      if (this?.portalData?.timezone) {
        let diff =
          startDate.utcOffset() -
          moment().tz(this.portalData.timezone).utcOffset();
        startDate.add(diff, "minutes");
        endDate.add(diff, "minutes");
      }
      this.startDate = startDate;
      this.endDate = endDate;
      this.$emit("interval-changed", {
        startDate: startDate,
        endDate: endDate
      });
    },
    config() {
      let entry = {
        parentEl: this.$refs.JQuerySlot,
        opens: "center",
        drops: "down",
        timePicker: true,
        timePicker24Hour: true,
        startDate: this.userTimezone(this.start),
        endDate: this.userTimezone(this.end),
        maxSpan: {
          hours: Math.abs(this.min ? this.min : 720)
        },
        isInvalidDate: function(arg) {
          return arg.d > this.endDate;
        },
        showTimezone: true,
        locale: {
          format: "DD/MM/YY H:mm",
          applyLabel: this.$t("apply"),
          cancelLabel: this.$t("Cancel"),
          fromLabel: this.$t("From"),
          toLabel: this.$t("To"),
          customRangeLabel: this.$t("Custom"),
          daysOfWeek: [
            this.$t("calendar.Su"),
            this.$t("calendar.Mo"),
            this.$t("calendar.Tu"),
            this.$t("calendar.We"),
            this.$t("calendar.Th"),
            this.$t("calendar.Fr"),
            this.$t("calendar.Sa")
          ],
          monthNames: [
            this.$t("calendar.January"),
            this.$t("calendar.February"),
            this.$t("calendar.March"),
            this.$t("calendar.April"),
            this.$t("calendar.May"),
            this.$t("calendar.June"),
            this.$t("calendar.July"),
            this.$t("calendar.August"),
            this.$t("calendar.September"),
            this.$t("calendar.October"),
            this.$t("calendar.November"),
            this.$t("calendar.December")
          ],
          firstDay: 1
        },
        alwaysShowCalendars: true
      };
      let calendarRanges = this.calendarRanges();
      if (calendarRanges) {
        entry.ranges = calendarRanges;
      }
      if (this.restricted) {
        entry.minDate = this.startRef.add(-1, "minute");
        entry.maxDate = this.endRef.add(1, "minute");
      } else {
        entry.maxDate = this.allowFutureDate
          ? moment().add(1, "year")
          : moment().add(1, "hour");
      }
      return entry;
    },
    setupPicker() {
      var self = this;
      if (!this.$refs.inp) return;
      this.destroyPicker();
      $(this.$refs.inp)
        .daterangepicker(this.config(), this.onIntervalChange)
        .on("show.daterangepicker", function() {
          let $el = $(this).data("daterangepicker");
          if ($el.ranges) {
            // important, otherwise last24 hours will not be updated;
            $el.ranges = self.calendarRanges();
          }
          self.$emit("dateRangePickerEvent", "show");
        })
        .on("hide.daterangepicker", function() {
          self.$emit("dateRangePickerEvent", "hide");
        })
        .on("apply.daterangepicker", function(ev, picker) {
          self.oneSecond = (self.oneSecond || 1) * -1; // force update
          self.onIntervalChange(picker.startDate, picker.endDate.add(self.oneSecond, 'seconds'));
        });
    },
    picker() {
      if (this.$refs.inp) {
        return $(this.$refs.inp).data("daterangepicker");
      }
      return null;
    },
    destroyPicker() {
      let $picker = this.picker();
      if ($picker) {
        let $el = $(this.$refs.inp);
        $el.off("show.daterangepicker");
        $el.off("hide.daterangepicker");
        $el.data("daterangepicker").remove();
        this.$refs.JQuerySlot.innerHTML = "";
        return true;
      }
      return false;
    },
    safeRebuild() {
      this.rebuilding = true;
      if (this.destroyPicker()) {
        this.$nextTick(() => {
          setTimeout(
            () => {
              this.setupPicker();
              this.rebuilding = false;
            },
            100,
            this
          );
        });
      }
    }
  },
  mounted() {
    this.hasSidebar = $("aside.main-sidebar").is(":visible");
    this.setupPicker();
  },
  beforeDestroy() {
    this.destroyPicker();
  }
};
</script>

<style scoped>
.me {
  position: relative;
  /* display: inline-block; */
  width: auto;
  max-width: 185px;
  letter-spacing: -1px;
  text-align: center;
  padding: 0 !important;
}

.daterange-container input {
  position: relative;
  letter-spacing: -1px;
  text-align: center;
  padding: 0 !important;
  background-color: transparent;
}

.JQuerySlot {
  position: absolute;
  width: auto;
  min-width: 700px;
  margin-left: 0;
  height: 0;
  font-size: 90%;
}

.no-sidebar {
  margin-left: 55px;
}

i.refresh {
  font-size: 40%;
  position: absolute;
  left: 3px;
  top: 3px;
  /* color: #5382a7; */
  color: #8ba6bc;
  z-index: 2;
}

i.refresh:hover {
  color: #5084ac;
}

@media (min-width: 764px) {
  .JQuerySlot {
    min-width: 650px;
    left: -36%;
  }
}
</style>

<style>
.daterangepicker .drp-buttons {
  text-align: center;
}

.with-ranges .daterangepicker .ranges {
  width: 100%;
}

.with-ranges .daterangepicker .ranges > ul {
  width: 100%;
}

.daterangepicker:before {
  top: inherit;
  border: none;
}

@media (min-width: 730px) {
  .with-ranges .daterangepicker .ranges {
    max-width: 140px;
    min-width: 70px;
    overflow: hidden;
    width: 108px;
    white-space: nowrap;
    resize: horizontal;
  }
}
</style>
