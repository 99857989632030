<template>
  <dropdown
    position="left"
    :data="queryLimits"
    :title="`${$t('up_to')} ${queryLimit} ${$t('days')}`"
    :applyButton="false"
    v-on:itemChanged="onQueryLimitChange"
  >
    <span style="font-size:75%">
      <span class="hidden-sm hidden-xs">{{ $t("up_to") }}</span>
      <span class=""> {{ label }}</span>
    </span>
  </dropdown>
</template>

<script>
import Dropdown from "@/plugins/dropdown/dropdown.vue";
export default {
  name: "IntervalDropDown",
  components: {
    Dropdown
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => ({
    queryLimit: 0
  }),
  computed: {
    label() {
      return `${this.queryLimit} ${this.$t("days")}`;
    },
    contract() {
      return this.$store.getters["user/loggedUser"]?.contract || null;
    },
    queryLimits() {
      let lst = this?.contract
        ? [
            this.contract.history_short_limit_to_query,
            this.contract.history_long_limit_to_query
          ]
        : [0, 0];
      return lst.map((value) => {
        return {
          label: `${this.$t(
            value == this.contract.history_short_limit_to_query
              ? "view"
              : "download"
          )} (${this.$t("up_to")} ${value} ${this.$t("days")})`,
          value: value,
          selected: value == this.queryLimit
        };
      });
    }
  },
  methods: {
    onQueryLimitChange(items) {
      let item = (items || []).find((i) => i.selected) || null;
      this.queryLimit = (item && item.value) || 0;
      this.$emit("input", this.queryLimit);
    }
  },
  mounted() {
    this.queryLimit = this.value || this.contract.history_short_limit_to_query;
  }
};
</script>

<style scoped></style>
